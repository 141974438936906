import React from 'react';
import PropTypes from 'prop-types';

import IconsRow from '../../components/IconsRow'

import LogoSalud from '../../icons/logo_salud.png';
import LogoServiciosSalud from '../../icons/logo_servicios_salud_jalisco.png';
import LogoSamu from '../../icons/logo_samu_jalisco.png';
import LogoHC from '../../icons/logo_h_c.png';
import LogoHGO from '../../icons/logo_hgo.png';
import logoIMSS from '../../icons/logo_imss.png';
import logoISSSTE from '../../icons/logo_issste.png';
import LogoEducacion from '../../icons/logo_educacion.png';
import LogoMexicoSedena from '../../icons/logo_mexico_sedena.png';
import LogoBienestar from '../../icons/logo_bienestar.png';

const logos = [
    
    {
        src: LogoSalud,
        alt: 'Logo Salud',
        maxWidth: '180px'
    },
    {
        src: LogoServiciosSalud,
        alt: 'Logo Servicios de Salud Jalisco',
        maxWidth: '180px'
    },
    {
        src: LogoSamu,
        alt: 'Logo Sistema de Atención Médica de Urgencias de Jalisco',
        maxWidth: '180px'
    },
    {
        src: LogoMexicoSedena,
        alt: 'Logo Gobierno de México Secretaría de la Defensa Nacional',
        maxWidth: '180px'
    },
    {
        src: LogoBienestar,
        alt: 'Logo Bienestar',
        maxWidth: '180px'
    },
];

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color, marginSides}) => {
    return (
        <IconsRow color={color} icons={logos} marginSides={marginSides} />
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
