import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './CurpHome.styles';

import CurpForm from '../CurpForm';
import JaliscoHacemos from '../JaliscoHacemos';
import AvisoLink from '../../components/AvisoLink';

const Container = styled.div`${Styles.container}`;
const Hacemos = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: 0;
`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <Container>
            <CurpForm />
            <div style={{display:'flex', width:'80%', flexDirection:'row', alignItems:'center', justifyContent:'space-between', zIndex:'2000'}}>
                <AvisoLink color={'#ffffff'}/>
                <span style={{fontWeight:'bold'}}>Dudas al teléfono <br/><a href="tel:3338233220" rel="noopener noreferrer" target="_blank" style={{color:'#ffffff'}}>33 3823 3220</a></span>
            </div>
            <Hacemos>
                <JaliscoHacemos />
            </Hacemos>
        </Container>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
