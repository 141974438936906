import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Styles from './AppointmentResult.styles';
import LogoExclamacion from '../../icons/signo-de-exclamacion.svg';
import SuccessLogo from '../../icons/success.svg';
import PropTypes from 'prop-types';
import PrimaryButton from '../../components/PrimaryButton';
import CampaignData from '../CampaignData';


const Container = styled.div`${Styles.container}`;
const ContainerInfoView = styled.div``;
const Icon = styled.img`${Styles.icon}`;
const ContainerInfo = styled.div`${Styles.containerInfo}`;
const Title = styled.h2`${Styles.title}`;
const TitleSuccess = styled.h2`${Styles.titleSuccess}`;
const Info = styled.p`${Styles.info}`;
const Instructions = styled.div`${Styles.instructions}`;
const TextLine = styled.div`${Styles.textLine}`;
const TextNormal = styled.span`${Styles.textLine}`;
const TextBold = styled.span`
    font-weight: bold;
`;
const Link = styled.a`
    font-weight: bold;
    color: #015DB1;
    cursor: pointer;
    text-decoration: underline;
`;

const CampaignDetails = styled.div`
    display: flex; 
    box-sizing: border-box; 
    font-size: 1em; 
    width: 100%; 
    flex-direction: row; 
    justify-content: space-between;
    flex-wrap:wrap; 
    align-items:center; 
    border-radius: 25px; 
    padding: 2em; 
    box-shadow: 0px 3px 12px #00000029;
    @media(max-width:768px){
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }
`;
const InvitacionDetails = styled.div`
    display:flex; 
    margin-top:1em; 
    box-sizing:border-box; 
    font-size:1em; 
    width:100%; 
    flex-direction:row; 
    justify-content:space-between; 
    align-items:flex-start;
    @media(max-width:768px){
        flex-direction: column;
    }
`;

const InvitacionDetailsElement = styled.div`
    width: 30%;
    @media(max-width:768px){
        width:100%;
        margin-top: 1.5em;
    }
`;

const RadioOption = styled.input.attrs({
    type: 'radio'
  })`
    ${Styles.radio}
  `
/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AppointmentResult = ({ appointment, curp, validation, error }) => {

    useEffect(() => {

    }, []);

    const routing = (route, blank = 0) => {
        blank ? window.open(`${route}`, '_blank') : window.location.href = `${route}`;
    }

    const CURPNotFoundView = () => {

        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="No encontrado" />
            </Container>
            <Container>
                <Title>{error.includes('dosis') || error.includes('citas') ? 'Datos fuera de convocatoria.' : 'Datos Inválidos.'}</Title>
                <br />
                {error && <TextLine>
                    <TextBold>{error}</TextBold>
                </TextLine>}
                <br />
                <br />
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_URL}`)}>Regresar</PrimaryButton>
            </Container>
        </>;
    }

    const UdeGView = (appointment) => {
        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="Alert" />
            </Container>
            <Container>
                <TitleSuccess>Atención</TitleSuccess>
                <TitleSuccess>Trabajador Universidad de Guadalajara</TitleSuccess>
                <br />
                
                <TextLine>
                    <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                </TextLine>
                <TextLine>
                    <TextNormal className="title">Nombre: </TextNormal><TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                </TextLine>
                <br />
                <TextLine>
                    <TextBold>Si eres trabajador de la Universidad de Guadalajara de un plantel del Área Metropolitana, deberás registrarte en <a href="http://www.vacuna.udg.mx" rel="noopener noreferrer" target="_blank">www.vacuna.udg.mx</a> para agendar tu cita en el módulo UdeG, ubicado en la Plaza Bicentenario del Centro Cultural Universitario.</TextBold>
                </TextLine>
            </Container>
        </>;
    }

    const OutFase = (appointment) => {
        return <>
            <Container className="icon">
                <Icon src={LogoExclamacion} alt="Alert" />
            </Container>
            <Container>
                <TitleSuccess>Atención</TitleSuccess>
                <br />
                
                <TextLine>
                    <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                </TextLine>
                <TextLine>
                    <TextNormal className="title">Nombre: </TextNormal><TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                </TextLine>
                <br />
                <TextLine>
                    <TextBold>En este  momento tu información está en proceso de programación,  te sugerimos  intentar más tarde.</TextBold>
                </TextLine>
            </Container>
        </>;
    }

    const successView = (appointment) => {
        console.log(appointment);
        return <>
            <Container>
                <TitleSuccess style={{alignItems:'center', justifyContent:'flex-start', display:'flex'}}><Icon style={{width:'1.25em', height:'1.25em', marginRight:'0.5em'}} src={SuccessLogo} alt="Success" /> Registro concluido</TitleSuccess>
                {appointment.validation && infoValidationView()}
                <br />
                <CampaignDetails>
                    <div>
                        <TextLine>
                            <TextNormal className="title">CURP: </TextNormal><TextBold>{appointment.curp}</TextBold>
                        </TextLine>
                        <TextLine>
                            <TextBold>{appointment.nombre + ' ' + appointment.primer_apellido + ' ' + appointment.segundo_apellido} </TextBold>
                        </TextLine>
                    </div>
                    <div style={{fontWeight:'bold'}}>
                        <CampaignData embarazada={appointment.campaigns.embarazada} edad={appointment.campaigns.edad_minima} municipio={appointment.municipio} dosis={appointment.num_dosis} nombre={appointment.campaigns.descripcion} />
                    </div>
                </CampaignDetails>
                <div style={{display:'flex', marginTop:'2em', boxSizing:'border-box', fontSize:'1em', width:'100%', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start', borderRadius:'25px', padding:'2em', boxShadow:'0px 3px 12px #00000029'}}>
                    <TextLine><TextBold>Se te ha asignado un folio in invitación para la vacuna contra el COVID-19</TextBold></TextLine>
                    <TextLine style={{marginTop:'0.5em'}}><TextBold style={{color:'#3B3A3A', fontSize:'1.5em'}}>FOLIO: {appointment.uuid}</TextBold></TextLine>
                    <InvitacionDetails>
                        <InvitacionDetailsElement>
                            <TextLine>
                                <TextBold>Módulo</TextBold> 
                            </TextLine>
                            <TextLine style={{marginTop:'1em'}}>
                                <TextBold style={{color:'#3B3A3A',}}>{appointment.modulo_vacunacion.nombre_modulo}.</TextBold>
                            </TextLine>
                        </InvitacionDetailsElement>
                        <InvitacionDetailsElement>
                            <TextLine>
                                <TextBold>Dirección</TextBold> 
                            </TextLine>
                            <TextLine style={{marginTop:'1em'}}>
                                <TextBold style={{color:'#3B3A3A'}}>{appointment.modulo_vacunacion.nombre_calle} {appointment.modulo_vacunacion.numero_exterior} {appointment.modulo_vacunacion.numero_interior}</TextBold> 
                            </TextLine>
                            <TextLine>
                                <TextBold style={{color:'#3B3A3A'}}>Col. {appointment.modulo_vacunacion.colonia}, {appointment.modulo_vacunacion.codigo_postal}</TextBold>
                            </TextLine>
                            <TextLine>
                                <TextBold style={{color:'#3B3A3A'}}>{appointment.modulo_vacunacion.municipio}, {appointment.modulo_vacunacion.entidad_federativa}</TextBold>
                            </TextLine>
                        </InvitacionDetailsElement>
                        <InvitacionDetailsElement>
                            <TextLine>
                                <TextBold>Fecha y Hora:</TextBold>
                            </TextLine>
                            <TextLine style={{marginTop:'1em', backgroundColor:'#37AEFD', borderRadius:'12px', color:'white', padding:'0.5em'}}>
                                <TextBold>{`${appointment.fecha_cita_string.day_text} ${appointment.fecha_cita_string.day} de ${appointment.fecha_cita_string.month} ${appointment.fecha_cita_string.hour}`}</TextBold>
                            </TextLine>
                        </InvitacionDetailsElement>
                    </InvitacionDetails>
                </div>

                {!appointment.validation && infoSuccessView()}
            </Container>
        </>;
    }

    const infoValidationView = () => {
        return <>
            <TextLine >
                <TextBold>A partir del martes podrás consultar en este mismo sitio web, sede, día y hora en que deberás presentarte para tu vacunación.</TextBold>
            </TextLine>
        </>;
    }

    const infoSuccessView = () => {
        return <>
            <ContainerInfoView>
                <Instructions>
                    <TextLine>
                        <TextBold>Ya tienes asegurado el lugar y hora para tu vacunación, ahora, para asegurar tu dosis es muy importante que también te registres en la plataforma federal. Ingresa a <a href="https://mivacuna.salud.gob.mx/" target="_blank" rel="noopener noreferrer">https://mivacuna.salud.gob.mx/</a> para obtener tu expediente de vacunación.</TextBold>
                    </TextLine>
                    <TextLine>
                        <TextBold>
                            Recomendaciones para el día de tu vacunación:
                        </TextBold>
                    </TextLine>
                    <br />
                    <TextLine>
                        <TextBold>
                            <ul>
                                <li style={{lineHeight:'1.25em', marginBottom:'0.5em'}}>Tomar tu medicamento o alimentos de forma habitual.</li>
                                <li style={{lineHeight:'1.25em', marginBottom:'0.5em'}}>Llegar a la hora indicada, máximo { appointment.modulo_vacunacion.campaign_id === 3 ? "20" : "10" } minutos antes de tu hora marcada.</li>
                                <li style={{lineHeight:'1.25em', marginBottom:'0.5em'}}>No llevar sillas, bancos, botes o cualquier otra cosa que sirva de asiento.</li>
                                <li style={{lineHeight:'1.25em', marginBottom:'0.5em'}}>Llevar ropa y calzado cómodo y en caso de lluvia paraguas o  impermeable.</li>
                                <li style={{lineHeight:'1.25em', marginBottom:'0.5em'}}>Trae una pluma o bolígrafo.</li>
                            </ul>
                        </TextBold>
                    </TextLine>
                </Instructions>
                <PrimaryButton onClick={() => routing(`${process.env.REACT_APP_DOWNLOAD_URL}/registro/${appointment.uuid}/getPdf`, 1)}>Descargar invitación</PrimaryButton>
                <br />
            </ContainerInfoView>
        </>;
    }

    const SelectType = ({ type, appointment }) => {
        let compMount = <>Sin resultado</>;
        if (appointment.udg){
            return UdeGView(appointment);
        }
        if (appointment.fase && appointment.fase !== "1" && !appointment.uuid){
            return OutFase(appointment);
        }
        if (type)
            switch (type) {
                case 1:
                    compMount = successView(appointment);
                    break;
                case 2:
                    compMount = CURPNotFoundView();
                    break;
            }
        return compMount;
    }

    const getTypeData = (curp, appointment) => {
        return {
            curp,
            ...appointment,
            validation
        }
    };

    return (
        <Container>
            <ContainerInfo>
                <SelectType type={(appointment) ? 1 : 2} appointment={getTypeData(curp, appointment)} />
            </ContainerInfo>
        </Container >
    );
};

AppointmentResult.propTypes = {
    type: PropTypes.string.isRequired
};

export default AppointmentResult;
