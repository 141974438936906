import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './ConfirmInfo.styles'

import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'

import AddressBook from '../../icons/address-book-solid.png'
import { Info } from '../../components/InfoData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import LogoExclamacion from '../../icons/signo-de-exclamacion.svg';

import {
  CheckboxInput,
  SelectInput,
} from '../../components/FormElement'
import CampaignData from '../../components/CampaignData'
import Modal from '../../components/Modal'


const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const Container = styled.div`
  ${Styles.container}
`
const SubContainer = styled.div`
  ${Styles.subcontainer}
`
const Form = styled.div`
  ${Styles.form}
`
const Title = styled.h2`
  ${Styles.title}
`

const SubTitle = styled.h2`
  ${Styles.subtitle}
`
const Text = styled.span`
  ${Styles.text}
`
const Icon = styled.img`
  ${Styles.icon}
`
const ActionButtons = styled.div`
  margin-top: 3em;
  display: flex;
  > * {
    height: 2.5em;
    margin-right: 1.5em;
  }
`

const CampaignInfo = styled.div`
  display:flex; 
  flex-wrap:wrap; 
  box-sizing:border-box; 
  font-size:1.25em; 
  width:100%; 
  flex-direction:row; 
  align-items:center; 
  justify-content:space-between; 
  border-radius:25px; 
  padding:2em; 
  box-shadow:0px 3px 12px #00000029;
  @media(max-width:768px){
    justify-content:center;
    flex-direction:column;
  }
`;

const ConfirmInfo = ({
  curp,
  nombre,
  primerApellido,
  segundoApellido,
  fechaNacimiento,
  edad,
  sexo,
  entidadDeNac,
  entidadFederativa,
  embarazada,
  semanas_gestacion,
  telefono1,
  telefono2,
  email,
  calle,
  numExt,
  numInt,
  cp,
  municipio,
  colonia,
  diabetes,
  hipertension,
  otra,
  num_dosis,
  fecha_aplicacion
}) => {
  //   const [modulo, setModulo] = useState('')
  //   const [assistance, setAssistance] = useState(false)
  const [modulos, setModulos] = useState([])
  const [loadingRegister, setLoadingRegister] = useState(true)
  const [user, setUser] = useState({})
  const [selectedModulo, setSelectedModulo] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [campaign, setCampaign] = useState({});
  const [showModal, setShowModal] = useState(false);
  useEffect(()=>{
    let storedCampaign = window.localStorage.getItem('campaign');
    const localCampaign = JSON.parse(storedCampaign);
    setCampaign(localCampaign);
    const localModulos = localCampaign.modulosVacunacion.map((modulo, idx)=>{
      return {
        value: modulo.id,
        caption: modulo.nombre_modulo,
        selected: idx === 0
      }
    });
    const selectModulos = localModulos.filter((modulo)=>{
      if([22, 23, 24, 27].includes(modulo.value)){
        return ["San Pedro Tlaquepaque", "Tlajomulco de Zúñiga", "El Salto"].includes(user.municipio);
      }
      return true;
    });
    setModulos(selectModulos);
    setSelectedModulo(localCampaign.modulosVacunacion[0].id);
    setLoadingRegister(false)
  }, [user]);
  useEffect(() => {
    setUser({
      curp,
      nombre,
      primerApellido,
      segundoApellido,
      fechaNacimiento,
      edad,
      sexo,
      entidadDeNac,
      entidadFederativa,
      embarazada,
      semanas_gestacion,
      telefono1,
      telefono2,
      email,
      calle,
      numExt,
      numInt,
      cp: cp,
      municipio: municipio,
      colonia,
      diabetes,
      hipertension,
      otra,
      num_dosis,
      fecha_aplicacion
    })
  }, [
    calle,
    colonia,
    cp,
    curp,
    diabetes,
    edad,
    email,
    entidadDeNac,
    entidadFederativa,
    embarazada,
    semanas_gestacion,
    fechaNacimiento,
    hipertension,
    municipio,
    nombre,
    numExt,
    numInt,
    num_dosis,
    otra,
    primerApellido,
    segundoApellido,
    sexo,
    telefono1,
    telefono2,
    fecha_aplicacion
  ])

  const handleModal = (close) => {
    if(close){
      setShowModal(false);
      return;
    }
    window.location.href=`${process.env.REACT_APP_URL}/`;
    return;
  }
  const registerCita = () => {
    setLoadingRegister(true)
    fetch(`${process.env.REACT_APP_API_URL}/registro/agendarCita`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({...user, id_modulo: selectedModulo, id_campaign: campaign.id})
    })
      .then(res => {
        console.log(res);
        if(!res.ok){
          throw(res);
        }
        return res.json()
      })
      .then(data => {
        setLoadingRegister(false)
        if (data.error) {
          alert(data.error_descripcion)
          return
        }
        setLoadingRegister(false)
        window.localStorage.setItem('cita', JSON.stringify(data.cita))
        window.localStorage.setItem('appointment', JSON.stringify(data.cita))
        window.localStorage.setItem(
          'validation',
          JSON.stringify(data.validacion)
        )
        window.location.href = `${process.env.REACT_APP_URL}/appointment`
      })
      .catch(e => {
        setLoadingRegister(false)
        alert("La demanda de registros en este momento es alta. Estamos trabajando para solucionar los inconvenientes. Por favor, intenta de nuevo más tarde.");
      })
  }

  let FormComponent = <></>

  if (loadingRegister)
    FormComponent = (
      <PrimaryButton className='wait' disabled={true}>
        <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
        &nbsp;
      </PrimaryButton>
    )
  else
    FormComponent = (
      <PrimaryButton
        // disabled={loadingRegister || modulo === '' || modulos.length === 0}
        disabled={loadingRegister || modulos.length === 0 || !acceptedTerms}
        onClick={registerCita}
      >
        Guardar
      </PrimaryButton>
    )

  return (
    // (modulos.length > 0 && (
    <Container style={{boxSizing:'border-box'}}>
      <SubContainer style={{padding:'0'}}>
        <Title style={{display:'flex', flexDirection:'row', alignItems:'center'}}><Icon src={AddressBook} alt='Icono libreta de direcciones' />Confirmación de información</Title>
        <Text>
          Por último es necesario verifiques que toda la información que se ha
          registrado sea correcta.
        </Text>
        <CampaignInfo>
          <CampaignData nombre={campaign.descripcion} edad={campaign.edad_minima} municipio={user.municipio} dosis={user.num_dosis} embarazada={campaign.embarazada} />
          <div style={{fontSize:'0.8rem', marginTop:'1em'}} >
            <SecondaryButton onClick={setShowModal}>
              Editar Campaña
            </SecondaryButton>
          </div>
        </CampaignInfo>

        <div style={{display:'flex', boxSizing:'border-box', width:'100%', flexDirection:'column', borderRadius:'25px', padding:'2em', marginTop:'2em', boxShadow:'0px 3px 12px #00000029'}}>
          <div style={{display:'flex', boxSizing:'border-box', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginBottom:'2em'}}>
            <SubTitle style={{marginTop:'0px', color:"rgba(103, 109, 114, 1)"}}>Registro</SubTitle>
            <div style={{fontSize:'0.8rem'}} >
              <SecondaryButton 
                disabled={false}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_URL}/register`
                }}>
                  Editar Registro
              </SecondaryButton>
            </div>
          </div>
          <Form>
            <SubTitle style={{marginTop:'0px'}}>Datos Personales</SubTitle>
            <Info caption='CURP' value={user.curp} />
            <Info caption='Nombre' value={user.nombre} />
            <Info caption='Primer apellido' value={user.primerApellido} />
            <Info caption='Segundo apellido' value={user.segundoApellido} />
            <Info
              caption='Fecha de nacimiento'
              value={fechaNacimiento.substring(0, 10)}
            />
            <Info caption='Edad' value={edad.toString()} />
            <Info caption='Entidad de nacimiento' value={user.entidadDeNac} />
            <Info
              caption='Sexo'
              value={user.sexo === 'M' ? 'Masculino' : 'Femenino'}
            />
          </Form>
          {campaign.embarazada === 1 && <Form>
              <SubTitle>Información Adicional</SubTitle>
              <Info caption='¿Está embarazada? ' value={embarazada == 1 ? 'Si' : 'No'} />
              <Info caption='Semanas de gestación' value={semanas_gestacion} />
            </Form>
          }
          {[6,7,8,10].includes(campaign.id) && <Form>
              <SubTitle>Información Adicional</SubTitle>
              <Info caption='Fecha de aplicación primera dosis' value={fecha_aplicacion} />
            </Form>

          }
          <Form>
            <SubTitle>Domicilio</SubTitle>
            <Info caption='Calle' value={user.calle} />
            <Info caption='Numero exterior' value={user.numExt} />
            <Info
              caption='Numero interior'
              value={user.numInt ? user.numInt : '-'}
            />
            <Info caption='Entidad federativa' value={user.entidadFederativa} />
            <Info caption='Código Postal' value={user.cp} />
            <Info caption='Municipio' value={user.municipio} />
            <Info caption='Colonia' value={user.colonia ? user.colonia : '-'} />
          </Form>
          <Form>
            <SubTitle>Contacto</SubTitle>
            <Info caption='Teléfono de contacto 1' value={telefono1} />
            <Info caption='Teléfono de contacto 2' value={telefono2} />
            <Info caption='Correo electrónico' value={email} />
          </Form>
          <Form>
            <SubTitle>Padecimientos</SubTitle>
            <Info caption='Diabetes' value={diabetes == 1 ? 'Si' : 'No'} />
            <Info
              caption='Hipertensión'
              value={hipertension == 1 ? 'Si' : 'No'}
            />
            <Info caption='Otra' value={otra ? otra : '-'} />
          </Form>
        </div>
        
        {!loadingRegister && modulos.length > 0 && acceptedTerms && 
          <div style={{display:'flex', boxSizing:'border-box', marginTop:'2em', width:'100%', flexDirection:'column', borderRadius:'25px', padding:'2em', boxShadow:'0px 3px 12px #00000029'}}>
            <Form>
              <SubTitle style={{marginTop:'0px'}}>Selecciona tu módulo de vacunación</SubTitle>
              <SelectInput
                caption='Módulo de vacunación'
                options={modulos}
                onChange={evt => {
                  setSelectedModulo(evt.target.value);
                }}
                selected={user.id_modulo}
              />
              { modulos.filter((modulo)=>{return modulo.caption.includes('Drive') || modulo.caption.includes('Automovil')}).length > 0 && <span style={{marginTop:'1em', color:'red', display:'flex', alignItems:'center', width:'100%', fontSize:'0.75rem', textAlign:'left'}}><Icon style={{marginRight:'1em'}}src={LogoExclamacion} />Para ser vacunado en los módulos Drive Thru es necesario presentarse en automóvil.</span>}
            </Form>
          </div>
        }
        {!loadingRegister && modulos.length === 0 && acceptedTerms && 
        <div style={{display:'flex', boxSizing:'border-box', fontSize:'1.25em', width:'100%', flexDirection:'column', borderRadius:'25px', padding:'2em', boxShadow:'0px 3px 12px #00000029'}}>
          <Form>
            <SubTitle style={{marginTop:'0px'}}>Selecciona tu módulo de vacunación</SubTitle>
            <span style={{marginTop:'1em', color:'red', display:'flex', alignItems:'center', width:'100%', fontSize:'0.75rem', textAlign:'left'}}><Icon style={{marginRight:'1em'}}src={LogoExclamacion} />Por el momento no hay módulos de vacunación disponibles en tu rango de edad, municipio, o dosis seleccionada. Te invitamos a mantenerte informado a través de los canales oficiales del gobierno para nuevas fechas.</span>
          </Form>
        </div>
        }
        <ActionButtons style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
          <CheckboxInput
              checked={acceptedTerms}
              label={'He leído y acepto que toda mi información es correcta.'}
              onChange={() => {
                setAcceptedTerms(!acceptedTerms)
              }}
          />
        </ActionButtons>
        <ActionButtons style={{alignItems:'center', justifyContent:'center', width:'100%',display:'flex'}}>
          {FormComponent}
        </ActionButtons>
      </SubContainer>
      <Modal
        handleClose = {handleModal}
        show = {showModal}
        headerText = "Confirmación"
        detailText = {<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
          <SubTitle style={{marginTop:'0px', color:'rgba(103, 109, 114, 1)', fontSize:'1.5rem', width:'100%', textAlign:'center'}}>¿Estás seguro que quieres cambiar de campaña?</SubTitle>
          <SubTitle style={{marginTop:'1rem', color:'rgba(103, 109, 114, 1)', fontSize:'0.8em', fontWeight:'normal', width:'100%', textAlign:'center'}}>Al cambiar de campaña comenzarás un registro nuevo.</SubTitle>
        </div>}
        openPos = 'CM_CENTER'
        showNo
        noCaption={'Cancelar'}
        yesCaption={'Aceptar'}

      />      
    </Container>
    // )) || <></>
  )
}

export default ConfirmInfo
