import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './CurpForm.styles'
import TextCurp from '../../components/TextCurp'
import PrimaryButton from '../../components/PrimaryButton'
import {
  HomeRadioInput,
  LargeSelectInput
} from '../../components/FormElement'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import CampaignData from '../../components/CampaignData'
import SecondaryButton from '../../components/SecondaryButton'
import Modal from '../../components/Modal'

const Container = styled.div`
  ${Styles.container}
`
const Title = styled.h3`
  ${Styles.header}
`
const BottomText = styled.span`
  ${Styles.bottom}
`
const ErrorText = styled.span`
  ${Styles.errortext}
`
const Link = styled.a`
  ${Styles.link}
`
const Form = styled.div`
  ${Styles.form}
`

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const CurpForm = () => {
  const [curp, setCurp] = useState('')
  const [numDosis, setDosis] = useState('')
  const [municipio, setMunicipio] = useState('');
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false)
  const [loadingMunicipio, setLoadingMunicipio] = useState(false)
  const [loadingDosis, setLoadingDosis] = useState(false)
  const [loadingCampaign, setLoadingCampaign] = useState(false)

  const [serviceAvailable, setServiceAvailable] = useState(true)
  const [loadingService, setLoadingService] = useState(true)
  const [campaigns, setCampaigns] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState({})
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')

  const [numDosisOptions, setNumDosisOptions] = useState([
    {
      value: 1,
      caption: 'Primera dosis'
    },
    {
      value: 2,
      caption: 'Segunda dosis'
    }
  ]);

  const initialNumDosis = [
    {
      value: 1,
      caption: 'Primera dosis'
    },
    {
      value: 2,
      caption: 'Segunda dosis'
    }
  ];

  const [modalTitle, setModalTitle] = useState('Lo sentimos');
  const [modalText, setModalText] = useState('');
  const [showModal, setShowModal] = useState('');
  useEffect(() => {
    setLoadingService(false)
  }, [])

  const municipios= [
    {
      value: '',
      caption: '',
      selected: true
    },
    {
      value:'Guadalajara',
      caption:'Guadalajara',
      selected: false
    },
    {
      value:'El Salto',
      caption:'El Salto',
      selected: false
    },
    {
      value:'Tlajomulco de Zúñiga',
      caption:'Tlajomulco de Zúñiga',
      selected: false
    },
    {
      value:'San Pedro Tlaquepaque',
      caption:'San Pedro Tlaquepaque',
      selected: false
    },
    {
      value:'Tonalá',
      caption:'Tonalá',
      selected: false
    },
    {
      value:'Zapopan',
      caption:'Zapopan',
      selected: false
    }
  ];

  const loadCurp = () => {
    window.grecaptcha.ready(() => {
      setLoading(true)
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        const curpPromise = fetch(
          `${process.env.REACT_APP_API_URL}/registro/getInfoByCurpAndCp?curp=${curp}&token=${token}`
        )
        .then((res)=>{
          if(!res.ok){
            throw(res);
          }
          return res.json()  
        })
        .then(({data, modulos, campaign, campaigns, municipios}) => {
            window.localStorage.setItem('curp', JSON.stringify(curp))

            if (data.cita) {
              data.cita.fecha_aplicacion = data.cita.fecha_cita.substring(0,10);
              if(
                (data.cita.fecha_aplicacion < '2021-07-04' && data.cita.num_dosis === 1 && [1, 5].includes(data.cita.campaigns.id)) ||
                (data.cita.fecha_aplicacion < '2021-07-26' && data.cita.num_dosis === 1 && [2].includes(data.cita.campaigns.id))
              ){
                const segundasDosis = campaigns.filter((campaign)=>{
                  return ([6, 7, 8, 10].includes(campaign.id) && campaign.modulosVacunacion);
                });
                if(segundasDosis.length === 0){
                  setModalText('Por el momento no hay citas disponibles para tu rango de edad o se han agotado.');
                  setShowModal(true);
                  setLoading(false);    
                  return;
                }
                data.cita.primerApellido = data.cita.primer_apellido;
                data.cita.segundoApellido = data.cita.segundo_apellido;
                data.cita.fechaNacimiento = data.cita.fecha_nacimiento;
                data.cita.estadoNacNombre = data.cita.entidad_de_nacimiento;
                data.cita.fecha_aplicacion = data.cita.fecha_cita.substring(0,10);
                window.localStorage.setItem('user', JSON.stringify(data.cita));
                window.localStorage.setItem('campaign', JSON.stringify(campaign));
                window.localStorage.setItem('municipios', JSON.stringify(municipios));
                setUser({ ...data.cita, selectedMunicipio: data.cita.municipio, num_dosis:data.cita.num_dosis, cp: ''});
                window.localStorage.setItem('cp', '')
                setMunicipio(data.cita.municipio);
                setDosis(2);
                setCampaigns(segundasDosis);
                setLoading(false);
                setStep(2);
                return ;
              } else {
                window.localStorage.setItem(
                  'appointment',
                  JSON.stringify(data.cita)
                )
                window.localStorage.setItem(
                  'validation',
                  JSON.stringify(data.validacion)
                )
                window.localStorage.setItem(
                  'userRegister',
                  JSON.stringify({ curp })
                )
                window.location.href = `${process.env.REACT_APP_URL}/appointment`
                return                
              }
            }
            if (data.error) {
              setModalText(data.error_descripcion);
              setShowModal(true);
              setLoading(false);
              return
            }
            if (!modulos || modulos.error) {
              if(modulos.error_descripcion){
                setModalText(modulos.error_descripcion);
              }else{
                setModalText('Ocurrió un error verificando sus datos. Por favor intente de nuevo más tarde.');
              }
              setShowModal(true);
              setLoading(false);
              return
            }
            if (modulos.length === 0) {
              setModalText('Por el momento no hay citas disponibles para tu rango de edad o se han agotado.');
              setShowModal(true);
              setLoading(false);
              return
            }
            if (!campaigns || campaigns.length === 0) {
              setModalText('Por el momento no hay citas disponibles para tu rango de edad o se han agotado.')
              setShowModal(true);
              setLoading(false);
              return
            }
            window.localStorage.setItem('user', JSON.stringify(data.data))
            window.localStorage.setItem('campaign', JSON.stringify(campaign));
            window.localStorage.setItem('municipios', JSON.stringify(municipios));
            setUser({ ...data.data, selectedMunicipio: municipio, num_dosis:numDosis, cp: ''});
            window.localStorage.setItem('cp', '')
            setCampaigns(campaigns);
            setLoading(false);
            setStep(2)
          })
          .catch(err => {
            setModalText('Ocurrió un error verificando sus datos. Por favor intente de nuevo más tarde.');
            setShowModal(true);
            setLoading(false)
          })
      })
    })
  }

  const handleModal = (close) => {
    if(close){
      setShowModal(false);
      return;
    }
    window.location.href=`${process.env.REACT_APP_URL}/`;
    return;
  }

  useEffect(()=>{
    if(loadingMunicipio){ 
      setSelectedMunicipio();
    }
  }, [loadingMunicipio]);

  useEffect(()=>{
    if(loadingDosis){ 
      setSelectedDosis();
    }
  }, [loadingDosis]);

  useEffect(()=>{
    if(loadingCampaign){ 
      moveToRegister();
    }
  }, [loadingCampaign]);

  const setSelectedMunicipio = () => {
    const filteredCampaigns = campaigns.filter((campaign)=>{
      return campaign.municipios && campaign.municipios.includes(municipio);
    });
    if(filteredCampaigns.length === 0){
      setModalText('Este municipio no cuenta con alguna campaña activa con dosis disponibles. Favor de mantenerte informado a través de medios oficiales.');
      setShowModal(true);
      setLoadingMunicipio(false);
      return
    } else {
      const dosis = filteredCampaigns.map((campaign)=>{return campaign.num_dosis});
      const newDosisSelections = initialNumDosis.filter((dosisData)=>{ return dosis.includes(dosisData.value); });
      setNumDosisOptions(newDosisSelections);
      if(newDosisSelections.length === 1){
        setDosis(newDosisSelections[0].value);
      }
      setSelectedCampaigns(filteredCampaigns)
      setLoadingMunicipio(false);
      setStep(3);
    }
  }

  const setSelectedDosis = () => {
    const filteredCampaigns = selectedCampaigns.filter((campaign)=>{
      return campaign.num_dosis == numDosis;
    });
    if(filteredCampaigns.length === 0){
      setModalText('Por el momento no hay citas disponibles para tu rango de edad o se han agotado.');
      setShowModal(true);
      setLoadingDosis(false);
      return
    }
    if(filteredCampaigns.length === 1){
      window.localStorage.setItem(
        'user',
        JSON.stringify({ ...user, selectedMunicipio: municipio, num_dosis:numDosis, cp: ''})
      );
      window.localStorage.setItem(
        'campaign', 
        JSON.stringify(filteredCampaigns[0])
      );
      setSelectedCampaign(filteredCampaigns[0]);
    }
    setSelectedCampaigns(filteredCampaigns);
    setLoadingDosis(false);
    setStep(4);
  }

  const moveToRegister = () => {
    window.localStorage.setItem(
      'user',
      JSON.stringify({ ...user, selectedMunicipio: municipio, num_dosis:numDosis, cp: ''})
    );
    window.localStorage.setItem(
      'campaign',
      JSON.stringify(selectedCampaign)
    );
    window.location.href = `${process.env.REACT_APP_URL}/register`;
    return ; 
  }

  let FormComponent = <></>
  if (!loadingService) {
    if (serviceAvailable && error) {
      FormComponent = (
        <Form>
          <ErrorText color={'#676D72'}>{error}</ErrorText>
        </Form>
      )
    }
    if (serviceAvailable && !error) {
      if (loading && step === 1)
        FormComponent = (
          <Form>
            <TextCurp value={curp} onChange={setCurp} />
            <PrimaryButton
              style={{marginTop:'1em'}}
              className='wait'
              disabled={curp.length !== 18 || loading}
            >
              <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
              &nbsp;Buscando ...
            </PrimaryButton>
          </Form>
        )
      else if(step===1){
        FormComponent = (
          <Form>
            <TextCurp value={curp} onChange={setCurp} />
            <PrimaryButton
              style={{marginTop:'1em'}}
              disabled={curp.length !== 18 || loading } 
              onClick={loadCurp}
            >
              {' '}
              Quiero vacunarme{' '}
            </PrimaryButton>
          </Form>
        )
      }else if(step === 2){
        FormComponent = (
        <Form>
          <LargeSelectInput
            options={municipios}
            onChange={evt => {
              setMunicipio(evt.target.value);
            }}
            selected={municipio}
          />
          <div style={{marginTop:'2em', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
            <SecondaryButton
              disabled={loadingDosis}
              onClick={()=>{setStep(1)}}
            >
              {' '}
              Regresar{' '}
            </SecondaryButton>
            <PrimaryButton
              style={{marginLeft:'2em'}}
              disabled={municipio.length == 0 || loadingMunicipio} 
              onClick={()=>{setLoadingMunicipio(true)}}
            >
              {' '}
              Continuar{' '}
            </PrimaryButton>
          </div>
        </Form>
        )
      }else if(step === 3){
        FormComponent = (<Form style={{width:'100%'}}>
          <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around', flexWrap:'wrap'}}>
            {
              numDosisOptions.map((dosis)=>{
                return (
                <div style={{cursor:'pointer', width:'40%', fontSize:'1.125em', marginTop:'1em', borderRadius:'25px', display:'flex', flexDirection:'column', boxSizing:'border-box', transition: 'border-color 0.3s linear, height 0.3s linear', display:'flex', alignItems:'center', justifyContent:'center', border: numDosis === dosis.value ? '4px solid #37AEFD' : '4px solid transparent', padding:'1em', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow:'0px 3px 12px #00000029'}} key={`campaign-${dosis.value}`} onClick={()=>{setDosis(dosis.value)}}>
                  <span style={{width:'1em', padding:'0.5em', fontWeight:'bolder', color:'white',display:'flex', alignItems:'center',justifyContent:'center', height:'1em', fontSize:'1em', background:'#37AEFE 0% 0% no-repeat padding-box', borderRadius:'50%'}}>{dosis.value}</span>
                  <span style={{color:'#00326C', marginTop:'0.5em', fontWeight:'bolder'}}>{dosis.caption}</span>
                </div>)
              })
            }
          </div>
          <div style={{marginTop:'2em', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
            <SecondaryButton
                disabled={loadingDosis}
                onClick={()=>{setStep(2)}}
              >
                {' '}
                Regresar{' '}
            </SecondaryButton>
            <PrimaryButton
              style={{marginLeft:'2em'}}
              disabled={!numDosis || loadingDosis}
              onClick={()=>{setLoadingDosis(true)}}
            >
              {' '}
              Continuar{' '}
            </PrimaryButton>
          </div>
        </Form>)
      }else if(step === 4){
        FormComponent = (<Form style={{width:'100%'}}>
          <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around', flexWrap:'wrap'}}>
            {
              selectedCampaigns.map((campaign)=>{
                return (
                <div style={{cursor:'pointer', marginTop:'1em', borderRadius:'25px', boxSizing:'border-box', transition: 'border-color 0.3s linear', height: '200px', display:'flex', alignItems:'center', justifyContent:'center', border: selectedCampaign.id === campaign.id ? '4px solid #37AEFD' : '4px solid transparent', padding:'1em', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow:'0px 3px 12px #00000029'}} key={`campaign-${campaign.id}`} onClick={()=>{setSelectedCampaign(campaign)}}>
                  <CampaignData column nombre={campaign.descripcion} municipio={municipio} edad={campaign.edad_minima} embarazada={campaign.embarazada} dosis={numDosis} />
                </div>)
              })
            }
          </div>
          <div style={{marginTop:'2em', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
            <SecondaryButton
              disabled={loadingCampaign}
              onClick={()=>{setStep(2)}}
            >
              {' '}
              Regresar{' '}
            </SecondaryButton>

            <PrimaryButton
              style={{marginLeft:'2em'}}
              disabled={!selectedCampaign.id || loadingCampaign}
              onClick={()=>{setLoadingCampaign(true)}}
            >
              {' '}
              Continuar{' '}
            </PrimaryButton>
          </div>
        </Form>)
      }
      
    }
    if (!serviceAvailable) {
      FormComponent = (
        <Form>
          <ErrorText color={'#676D72'}>{error}</ErrorText>
        </Form>
      )
    }
  }

  return (
    <Container>
      {!error && !loadingService && step === 1 && (
        <Title color={'#676D72'}>¡Comienza ahora!</Title>
      )}
      {!error && !loadingService && step === 2 && (
        <Title color={'#676D72'}>Selecciona tu municipio de residencia</Title>
      )}
      {!error && !loadingService && step === 3 && (
        <>
          <Title color={'#676D72'}>Selecciona la dosis</Title>
          <Title color={'#676D72'} style={{fontSize:'1em'}}>Dosis disponibles en : <span style={{color:"#014d98"}}>{municipio}</span></Title>
        </>
      )}
      {!error && !loadingService && step === 4 && (
        <>
        <Title color={'#00326C'}>Encontramos {selectedCampaigns.length} campaña{selectedCampaigns.length > 1 ? 's' : ''} para ti</Title>
        {selectedCampaigns.length > 1 && <Title color={'#676D72'}>Selecciona solamente una</Title>}
        </>
      )}


      {FormComponent}
      {!error && !loadingService && step===1 && (
        <BottomText>
          Si no conoces tu CURP{' '}
          <Link
            href='https://www.gob.mx/curp/'
            target='_blank'
            rel='noopner noreferrer'
          >
            consulta aquí
          </Link>
        </BottomText>
      )}
      {error && !loadingService && serviceAvailable && (
        <PrimaryButton
          onClick={() => {
            setError('')
          }}
        >
          Regresar
        </PrimaryButton>
      )}
      <Modal
        handleClose = {handleModal}
        show = {showModal}
        showNo = {step !== 1}
        headerText = {modalTitle}
        detailText = {modalText}
        openPos = 'CM_CENTER'
        noCaption = 'Cerrar'
      />
    </Container>
  )
}

CurpForm.propTypes = {}

export default CurpForm
