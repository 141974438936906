import { css } from 'styled-components';

const styles = {
    container: () => css`
        position: relative;
        background: linear-gradient(to bottom, white 50% , #00326C 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    `,
      
    icon: () => css`
        position: absolute;
        pointer-events: none;
        left: 0px;
        transform: translateX(-50%);
    `,
    texto: () => css`
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,
};

export default styles;
