import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Styles from './RegisterForm.styles'

import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'
import LogoExclamacion from '../../icons/signo-de-exclamacion.svg';

import {
  SelectInput,
  TextInput,
  RadioInput,
  JaliscoRadioInput,
  JaliscoCheckboxInputOtra
} from '../../components/FormElement'
import SenalAccesibilidad from '../../icons/senal-de-accesibilidad.svg'
import { JaliscoCheckboxInput } from '../../components/FormElement'

import AddressBook from '../../icons/address-book-solid.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { validate } from '../../utils/ValidationHelper'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const Container = styled.div`
  ${Styles.container}
`
const SubContainer = styled.div`
  ${Styles.subcontainer}
`
const Form = styled.div`
  ${Styles.form}
`
const Title = styled.h2`
  ${Styles.title}
`
const SubTitle = styled.h2`
  ${Styles.subtitle}
`
const Text = styled.span`
  ${Styles.text}
`
const Icon = styled.img`
  ${Styles.icon}
`
const ActionButtons = styled.div`
  margin-top: 3em;
  display: flex;
  > * {
    height: 2.5em;
    margin-right: 1.5em;
  }
`

const RegisterForm = ({
  curp = '',
  nombre = '',
  primerApellido = '',
  segundoApellido = '',
  fechaNacimiento = '',
  edad = '',
  sexo = '',
  estadoNacNombre = '',
  selectedMunicipio,
  num_dosis,
  renapo = true,
  cp,
  campaign,
  fecha_aplicacion
}) => {
  let defaultUser = {
    curp,
    nombre,
    primerApellido,
    segundoApellido,
    fechaNacimiento,
    edad,
    sexo,
    entidadDeNac: estadoNacNombre,
    entidadFederativa: 'Jalisco',
    telefono1: '',
    telefono2: '',
    email: '',
    calle: '',
    numExt: '',
    numInt: '',
    cp: '',
    municipio: selectedMunicipio,
    colonia: '',
    diabetes: '0',
    hipertension: '0',
    otra: '',
    num_dosis,
    fecha_aplicacion
  }

  let userRegister = window.localStorage.getItem('userRegister')
  if (userRegister) defaultUser = JSON.parse(userRegister)

  let hasOtraStart = !!defaultUser.otra

  const [municipio, setMunicipio] = useState()
  const [numDosis, setNumDosis] = useState(0)
  const [colonias, setColonias] = useState([])
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(defaultUser)
  const [hasOtra, setHasOtra] = useState(hasOtraStart)
  const [showForm, setShowForm] = useState(false);
  const selectDosage = evt => {
    setNumDosis(evt.target.value)
  }

  const numDosisOptions = [
    {
      value: 1,
      caption: '1ra dosis'
    },
    {
      value: 2,
      caption: '2da dosis'
    }
  ]

  const padecimientosOptions = [
    {
      value: 1,
      caption: 'Si'
    },
    {
      value: 0,
      caption: 'No'
    }
  ];

  const embarazadaOptions = [
    {
      value: 1,
      caption: 'Si'
    },
    {
      value: 0,
      caption: 'No'
    }
  ];

  const municipios = JSON.parse(window.localStorage.getItem('municipios')).map((municipio, idx)=>{
    return {
      value: municipio,
      caption: municipio,
      selected: idx === 0
    }
  });

  useEffect(()=>{
    if(!num_dosis){
      alert('Debe iniciar el registro desde la nueva versión de la plataforma.');
      window.location.href =`${process.env.REACT_APP_URL}`;
      return;
    }
    if(campaign.embarazada !== 1){
      setShowForm(true);
    }else{
      setUser({...user, embarazada:1});
    }
  }, []);
  
  useEffect(()=>{
    if(campaign.embarazada === 1 && user.embarazada !== 1){
      setShowForm(false);
    }
  }, [user.embarazada])

  useEffect(()=>{
    if([6,7,8,10].includes(campaign.id)){
      setShowForm(false);
    }
  }, [user.fecha_aplicacion]);

  const registerCita = () => {
    if ([6,7,8,10].includes(campaign.id) && !showForm){
      if(!user.fecha_aplicacion) return;
      if(user.fecha_aplicacion > campaign.max_date){
        window.localStorage.setItem('error', 'Por ahora el registro de segunda dosis está habilitado para quienes recibieron su primera dosis hasta el ' + campaign.max_date_text + '.');
        window.localStorage.setItem('errorTitle', 'Datos fuera de Convocatoria');
        window.location.href=`${process.env.REACT_APP_URL}/appointment`;
        return;
      }
      setShowForm(true);
      return;
    }
    if (campaign.embarazada == 1 && !showForm){
      if(user.embarazada == 0){
        window.localStorage.setItem('error', 'Por ahora el sistema está habilitado solo para las personas dentro del sector de edad señalado.');
        window.location.href=`${process.env.REACT_APP_URL}/appointment`;
        return;
      }
      let semanas_gestacion = user?.semanas_gestacion || "";
      if(semanas_gestacion.length === 0){
        semanas_gestacion = '0';
      }
      if(parseInt(semanas_gestacion) < campaign.semanas_gestacion_min){
        alert('Las semanas de gestación señaladas, no permiten llevar a cabo el proceso de vacunación de acuerdo a los criterios nacionales.');
        return;
      }
      setShowForm(true);
      return;
    }

    let validation = validate(user)
    if (validation !== true) return alert(validation)

    user.renapo = renapo
    window.localStorage.setItem('user', JSON.stringify(user))
    window.localStorage.setItem('userRegister', JSON.stringify(user))
    window.localStorage.setItem('curp', JSON.stringify(curp))
    window.location.href = `${process.env.REACT_APP_URL}/confirm`
    return
  }

  return (
    <Container>
      <SubContainer>
        <Title style={{display:'flex', flexDirection:'row', alignItems:'center'}}><Icon src={AddressBook} alt='Icono libreta de direcciones' /> Registro para Vacunación</Title>
        <Text>
          Es importante verifiques que la información que se presenta a
          continuación, corresponde a la CURP que estas solicitando y que
          coincide con los datos proporcionados
        </Text>
        <Form>
          <TextInput disabled caption='CURP' value={curp} />
          <TextInput
            disabled={renapo}
            caption='Nombre'
            value={user.nombre}
            onChange={evt => {
              setUser({ ...user, nombre: evt.target.value })
            }}
          />
          <TextInput
            disabled={renapo}
            caption='Primer apellido'
            value={user.primerApellido}
            onChange={evt => {
              setUser({ ...user, primerApellido: evt.target.value })
            }}
          />
          <TextInput
            disabled={renapo}
            caption='Segundo apellido'
            value={user.segundoApellido}
            onChange={evt => {
              setUser({ ...user, segundoApellido: evt.target.value })
            }}
          />
          <TextInput
            disabled
            caption='Fecha de nacimiento'
            value={fechaNacimiento.substring(0, 10)}
          />
          <TextInput disabled caption='Edad' value={edad.toString()} />
          <TextInput
            disabled
            caption='Entidad de nacimiento'
            value={user.entidadDeNac}
          />
          <TextInput
            disabled
            caption='Sexo'
            value={sexo === 'M' ? 'Masculino' : 'Femenino'}
          />
        </Form>
        { campaign.embarazada === 1 && campaign.num_dosis !== 2 && <Form>
          <SubTitle>Información adicional</SubTitle>
          { user.embarazada && user.embarazada == 1 && <TextInput
            type='number'
            caption='Semanas de gestación:'
            onChange={evt => {
              if (evt.target.value.length > 2) return
              setShowForm(false);
              setUser({ ...user, semanas_gestacion: evt.target.value })
            }}
            value={user.semanas_gestacion}
          />}
        </Form> }
        { campaign.num_dosis === 2 && [6,7,8,10].includes(campaign.id) && <Form>
          <SubTitle>Información adicional</SubTitle>
          <TextInput
            type='date'
            caption='Fecha aplicación primera dosis:'
            minDate='2021-05-20'
            maxDate={new Date().toISOString().substring(0,10)}
            onChange={evt => {
              setShowForm(false);
              setUser({ ...user, fecha_aplicacion: evt.target.value })
            }}
            value={user.fecha_aplicacion}
          />
          <span style={{marginTop:'1em', color:'red', display:'flex', alignItems:'center', width:'100%', fontSize:'0.75rem', textAlign:'left'}}><Icon style={{marginRight:'1em'}}src={LogoExclamacion} />¡No lo olvides! Deberás presentar tu comprobante de aplicación de primera dosis con fecha hasta el {campaign.max_date_text}.</span>
        </Form> }

        { showForm && <Form>
          <SubTitle>Contacto</SubTitle>
          <TextInput
            type='number'
            caption='Teléfono de contacto 1'
            onChange={evt => {
              if (evt.target.value.length > 10) return
              setUser({ ...user, telefono1: evt.target.value })
            }}
            value={user.telefono1}
          />
          <TextInput
            type='number'
            caption='Teléfono de contacto 2'
            onChange={evt => {
              if (evt.target.value.length > 10) return
              setUser({ ...user, telefono2: evt.target.value })
            }}
            value={user.telefono2}
          />
          <TextInput
            caption='Correo electrónico'
            onChange={evt => {
              setUser({ ...user, email: evt.target.value })
            }}
            value={user.email}
          />
        </Form>}
        {showForm && <Form>
          <SubTitle>Domicilio</SubTitle>
          <TextInput
            caption='Calle'
            onChange={evt => {
              setUser({ ...user, calle: evt.target.value })
            }}
            value={user.calle}
          />
          <TextInput
            type='number'
            caption='Numero exterior'
            onChange={evt => {
              setUser({ ...user, numExt: evt.target.value })
            }}
            value={user.numExt}
          />
          <TextInput
            caption='Numero interior'
            optional
            onChange={evt => {
              setUser({ ...user, numInt: evt.target.value })
            }}
            value={user.numInt}
          />
          <TextInput disabled caption='Entidad federativa' value={'Jalisco'} />
          <TextInput 
            type='number'
            value={user.cp}
            onChange={evt => {
              if (evt.target.value.length > 5) return;
              setUser({...user, cp: evt.target.value});
            }} 
            caption='Código Postal' 
          />
          <SelectInput
            disabled
            caption='Municipio'
            options={municipios}
            selected={user.municipio}
          />

          <TextInput caption='Colonia' value={user.colonia}
            onChange={evt => {
              setUser({ ...user, colonia: evt.target.value })
            }}
          />
          {loading && (
            <FontAwesomeIcon icon={faCircleNotch} spin color='white' />
          )}
        </Form>
        }
        {showForm && <span style={{marginTop:'1em', color:'red', display:'flex', alignItems:'center', width:'100%', fontSize:'0.75rem', textAlign:'left'}}><Icon style={{marginRight:'1em'}}src={LogoExclamacion} />Recuerda que tendrás que presentar un comprobante de domicilio del municipio seleccionado.</span>}
        {showForm && <Form>
          <SubTitle>Padecimientos</SubTitle>
          <JaliscoRadioInput
            selected={parseInt(user.diabetes)}
            onChange={evt => {
              setUser({ ...user, diabetes: evt.target.value })
            }}
            options={padecimientosOptions}
            groupName='diabetes'
            style={{ marginTop: 0 }}
            caption={'Diabetes'}
          />
          <JaliscoRadioInput
            selected={parseInt(user.hipertension)}
            onChange={evt => {
              setUser({ ...user, hipertension: evt.target.value })
            }}
            options={padecimientosOptions}
            groupName='hipertension'
            style={{ marginTop: 0 }}
            caption={'Hipertensión'}
          />
          <JaliscoCheckboxInputOtra
            checked={hasOtra}
            label={'Otra'}
            onChange={() => {
              let hasOtraAux = !hasOtra
              if (!hasOtraAux) setUser({ ...user, otra: '' })
              setHasOtra(hasOtraAux)
            }}
            image={false}
            inputOnChange={evt => {
              setUser({ ...user, otra: evt.target.value })
            }}
            inputValue={user.otra}
          />
        </Form>
        }
        <ActionButtons>
          <SecondaryButton
            disabled={false}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_URL}`
            }}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton disabled={loadingRegister} onClick={registerCita}>
            Confirmar
          </PrimaryButton>
        </ActionButtons>
      </SubContainer>
    </Container>
  )
}

export default RegisterForm
