import React from 'react';
import PropTypes from 'prop-types';

import IconsRow from '../../components/IconsRow'

import LogoJaliscoDefiende from '../../icons/logo_jalisco_se_defiende_oscuro.png';
import LogoGobiernoDelEstado from '../../icons/logo_gobierno_jalisco_horizontal.png';
import styled from 'styled-components'

const Container = styled.div`
    width:50%;
    @media(max-width:768px){
        width: 100%;
    }
`
const logos = [
    {
        src: LogoJaliscoDefiende,
        alt: 'Logo Jalisco Se Defiende',
        maxWidth: '180px'
    },
    {
        src: LogoGobiernoDelEstado,
        alt: 'Logo Gobierno del Estado de Jalisco',
        maxWidth: '180px'
    }
];

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <Container >
            <IconsRow color={color} icons={logos} flex={1} />
        </Container>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
