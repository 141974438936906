import { css } from 'styled-components';

const styles = {
    header: ({color}) => css`
        font: normal normal normal 1.5em Nutmeg;
        font-weight: bold;
        margin-bottom: 0px;
        margin-top: 0px;
        color: ${color}
    `,
    form: () => css`
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-around;
        width: 50%;
        padding: 1em 0;
        @media(max-width: 780px){
            width: 90%;
        }
        @media(max-width: 1024px){
            width: 75%;
        }
    `,
    container: () => css`
        width: 50%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 1em;
        padding-bottom: 1em;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 1em;
        box-shadow: 0px 3px 15px #00000027;
        opacity: 1;
        color: #676D72;
        font-size: 1.25em;
        padding: 1.5em;
        z-index:25;
        @media(max-width: 780px){
            width: 90%;
        }
        @media(max-width: 1024px){
            width: 75%;
        }
    `,
    link: () => css`
        text-decoration: none;
        color: #37AEFD;
        :hover{
            text-decoration: underline;
            color: #015DB1;
        }
    `,
    bottom: () => css`
        text-align: center;
    `,
    errortext: () => css`
        text-align: justify;
        margin-top: 1.5em;
    `
};

export default styles;